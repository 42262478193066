import { ApiClient } from './apiClient';
import { ChatMessage, ChatRequest, ChatOptions } from 'services/api/models';
import { protectedResources } from 'config/authConfig';
import { ApiKeyAuthorizationProvider } from './authorizationProvider';

export class ChatApiClient extends ApiClient {
  constructor(tenantId: string, apiKey: string) {
    super(
      protectedResources.chatApi.baseUrl,
      new ApiKeyAuthorizationProvider(tenantId, apiKey)
    );
  }

  async chatStream(messages: ChatMessage[], options?: ChatOptions) {
    const endpoint = `/chat`;
    const request: ChatRequest = {
      messages,
      stream: true,
      context: options?.context,
      session_state: options?.session
    };
    return this.postStream(endpoint, {
      requestBody: request
    });
  }
}
