import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  responsiveFontSizes
} from '@mui/material';
import {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useEffect
} from 'react';

import { getTheme } from 'styles/overrides';

interface AppThemeState {
  toggleColorMode: () => void;
}

const initialState: AppThemeState = {
  toggleColorMode: () => {}
};

export const AppThemeContext = createContext<AppThemeState>(initialState);

export const AppThemeProvider: React.FC<PropsWithChildren> = (
  props: PropsWithChildren
) => {
  const [colorMode, setColorMode] = useState<PaletteMode>(() => {
    const persistedState = window.localStorage.getItem('APP_COLOR_MODE');
    if (persistedState !== null) return JSON.parse(persistedState);
    return 'light';
  });

  // Save the user's color mode to local storage to persist on refresh
  useEffect(() => {
    window.localStorage.setItem('APP_COLOR_MODE', JSON.stringify(colorMode));
  }, [colorMode]);

  // Regenerate the theme when the color mode changes
  const theme = useMemo(() => {
    let theme = getTheme(colorMode);
    theme = responsiveFontSizes(theme);
    return theme;
  }, [colorMode]);

  const toggleColorMode = () => {
    setColorMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <AppThemeContext.Provider value={{ toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </AppThemeContext.Provider>
  );
};
