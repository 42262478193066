import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { isDevelopment } from 'config/appConfig';

const appInsightsReactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true,
    extensions: [appInsightsReactPlugin]
  }
});

export function initializeMetricsTracking() {
  // Only initialize App Insights monitoring in non-development
  // environment
  if (!isDevelopment()) {
    appInsights.loadAppInsights();
  }
}

export { appInsightsReactPlugin, appInsights };
