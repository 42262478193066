import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { Qna } from 'services/api/models';
import { Trans, useTranslation } from 'react-i18next';

interface DeleteQnaPopupProps {
  open: boolean;
  item: Qna;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteQnaPopup: React.FC<DeleteQnaPopupProps> = (
  props: DeleteQnaPopupProps
) => {
  const { item } = props;
  const { question } = item;

  /*
   * ************** Providers *********************
   */
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna.editor.popup.delete'
  });

  /*
   * ************** Render *********************
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Typography>
          <Trans>
            {t('message')} &apos;{{ question }}&apos;?
          </Trans>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={props.onDelete}
        >
          {t('confirmButton')}
        </Button>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          onClick={props.onClose}
        >
          {t('cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteQnaPopup;
