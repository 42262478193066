import { AcquireTokenFunction } from './api/authorizationProvider';
import { GroupsResponse } from './api/models';
import { SecurityApiClient } from './api/securityApiClient';

export class SecurityService {
  private securityApiClient: SecurityApiClient;

  constructor(tenantId: string, acquireToken: AcquireTokenFunction) {
    this.securityApiClient = new SecurityApiClient(tenantId, acquireToken);
  }

  async getApiKey(tenantId: string): Promise<string> {
    try {
      return this.securityApiClient.getApiKey(tenantId);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error fetching API key: ${error.message}`);
      throw error;
    }
  }

  async generateApiKey(tenantId: string): Promise<string> {
    try {
      return this.securityApiClient.generateApiKey(tenantId);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error generating API key: ${error.message}`);
      throw error;
    }
  }

  async getTenantSecurityGroups(tenantId: string): Promise<GroupsResponse> {
    try {
      return this.securityApiClient.getTenantSecurityGroups(tenantId);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error generating API key: ${error.message}`);
      throw error;
    }
  }
}
