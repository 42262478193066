import {
  InteractionRequiredAuthError,
  SilentRequest
} from '@azure/msal-browser';
import { TokenClaims } from '@azure/msal-common';
import { useAccount, useMsal } from '@azure/msal-react';
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState
} from 'react';

import { SecurityService } from 'services/securityService';
import { getRoleName } from 'config/authConfig';

interface AuthContextState {
  tenantId: string;
  apiKey: string;
  fetchApiKey: (tenantId: string) => Promise<void>;
  userName: string;
  userEmail: string;
  userRoles: string[];
  userGroups: string[];
}

const defaultState: AuthContextState = {
  tenantId: '',
  apiKey: '',
  fetchApiKey: async () => {},
  userName: '',
  userEmail: '',
  userRoles: [],
  userGroups: []
};

export const AuthContext = createContext<AuthContextState>(defaultState);

type IdTokenClaims =
  | (TokenClaims & {
      [key: string]: unknown;
    })
  | undefined;

const getRolesFromIdToken = (tokenClaims: IdTokenClaims): string[] => {
  if (!tokenClaims?.roles) return [];

  return tokenClaims.roles;
};

const getGroupsFromIdToken = (tokenClaims: IdTokenClaims): string[] => {
  if (!tokenClaims?.groups) return [];

  return tokenClaims.groups as string[];
};

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const account = useAccount();
  const { instance } = useMsal();

  const [apiKey, setApiKey] = useState('');

  const tenantId = account?.tenantId ?? '';
  const userName = account?.name ?? '';
  const userEmail = account?.username ?? '';
  const userGroups = getGroupsFromIdToken(account?.idTokenClaims);

  // Get roles from ID token. If there are none assigned, default role
  // is 'User'
  let userRoles = getRolesFromIdToken(account?.idTokenClaims).map(
    (role) => getRoleName(role) ?? ''
  );
  if (userRoles.length === 0) {
    userRoles = ['User'];
  }

  const acquireToken = async (scope: string) => {
    const request: SilentRequest = {
      account: instance.getActiveAccount() ?? undefined,
      scopes: [scope]
    };

    try {
      return instance.acquireTokenSilent(request);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
      }

      throw error;
    }
  };
  const securityService = new SecurityService(tenantId, acquireToken);

  const fetchApiKey = async (tenantId: string) => {
    const apiKey = await securityService.getApiKey(tenantId);
    setApiKey(apiKey);
  };

  useEffect(() => {
    if (account) {
      fetchApiKey(tenantId);
    }
  }, [account]);

  return (
    <AuthContext.Provider
      value={{
        tenantId,
        apiKey,
        fetchApiKey,
        userName,
        userEmail,
        userRoles,
        userGroups
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
