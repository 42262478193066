// --------------------------------------------
// Chat API
// --------------------------------------------
export type ChatMessage = {
  role: 'system' | 'user' | 'assistant' | 'function';
  content: string;
};

export type ChatRequest = {
  messages: ChatMessage[];
  stream?: boolean;
  context?: ChatRequestContext;
  session_state?: ChatSessionState;
};

export type ChatRequestContext = Record<string, any> & {
  top?: number;
  allowed_tags?: string[];
  useRag?: boolean;
};

export type ChatOptions = {
  stream?: boolean;
  context?: ChatRequestContext;
  session?: ChatSessionState;
};

export type ChatSessionState = Record<string, any> & {
  thread_id?: string;
  paused?: boolean;
};

export type ChatResponse = {
  choices: Array<{
    index: number;
    message: ChatResponseMessage;
    finish_reason: string | null;
  }>;
  object: 'chat.completion';
};

export type ChatResponseChunk = {
  choices: Array<{
    index: number;
    delta: Partial<ChatResponseMessage>;
    finish_reason: string | null;
  }>;
  object: 'chat.completion.chunk';
};

export type ChatResponseMessage = ChatMessage & {
  context?: Record<string, any> & {
    data_points?: Record<string, Citation>;
  };
  session_state: ChatSessionState;
};

export type Citation = {
  type: 'file' | 'qna';
  title: string;
  page: string;
  section: string;
  content?: string;
};

// --------------------------------------------
// Data API - Accounts
// --------------------------------------------
export class AccountUpdateRequestBody {
  constructor(
    public name?: string,
    public knowledgeMasterEmail?: string,
    public groupTags?: Array<GroupTag>
  ) {}
}

export type GroupTag = {
  groupId: string;
  tags: Array<string>;
};

export type AccountsResponse = {
  tenantId: string;
  name: string;
  knowledgeMasterEmail: string;
  groupTags: Array<GroupTag>;
};

// --------------------------------------------
// Data API - Files
// --------------------------------------------
export type FileMetadata = {
  category?: string;
  tags?: Array<string>;
};

export type FilesResponse = {
  files: FileData[];
};

export type FileData = {
  name: string;
  content?: string;
  tags: Array<string>;
};

export type GetFileMetadataOptions = {
  includeContent?: boolean;
  tags?: Array<string>;
};

// --------------------------------------------
// Data API - Q&As
// --------------------------------------------
export class Qna {
  constructor(
    public question: string,
    public answer: string,
    public tags?: Array<string>,
    public id?: number
  ) {}
}

export type GetQnasOptions = {
  top?: number;
  skip?: number;
  tags?: Array<string>;
};

export type QnasResponse = {
  count: number;
  qnas: Qna[];
};

// --------------------------------------------
// Security API - Groups
// --------------------------------------------
export type GroupsResponse = Array<TenantGroup>;

export type TenantGroup = {
  id: string;
  displayName: string;
};
