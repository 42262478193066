import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, useTheme } from '@mui/material';

import { ChatResponse } from 'services/api/models';
import SupportingContent from '../SupportingContent/SupportingContent';
import { useTranslation } from 'react-i18next';

export enum AnalysisPanelTabs {
  SupportingContentTab = 'supportingContent'
}

interface AnalysisPanelProps {
  activeTab: AnalysisPanelTabs;
  onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
  message: ChatResponse;
}

const AnalysisPanel: React.FC<AnalysisPanelProps> = (
  props: AnalysisPanelProps
) => {
  const { activeTab, onActiveTabChanged, message } = props;

  const supportingContent =
    message.choices[0].message.context?.data_points ?? {};

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.analysis'
  });

  /*
   * ************** Render **************
   */
  return (
    <Box>
      <TabContext value={activeTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.divider
          }}
        >
          <TabList
            onChange={(_e, v: AnalysisPanelTabs) => onActiveTabChanged(v)}
          >
            <Tab
              label={t('tabs.supportingContent.title')}
              value={AnalysisPanelTabs.SupportingContentTab}
            />
          </TabList>
        </Box>
        <TabPanel value={AnalysisPanelTabs.SupportingContentTab}>
          <SupportingContent
            supportingContent={Object.values(supportingContent)}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default AnalysisPanel;
