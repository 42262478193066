import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, Pagination, Skeleton, Stack, useTheme } from '@mui/material';

import { AccountsResponse, Qna } from 'services/api/models';
import QnaItem from '../QnaItem/QnaItem';
import CreateQnaPopup from '../../modal/CreateQnaPopup/CreateQnaPopup';
import QnaEditorActionBar from '../QnaEditorActionBar/QnaEditorActionBar';
import { appConfig } from 'config/appConfig';
import { useAppServices } from 'hooks/useAppServices';
import { useAuth } from 'hooks/useAuth';
import { getAllowedTagsForUser } from 'util/tags';
import { useTranslation } from 'react-i18next';

interface QnaEditorProps {
  account: AccountsResponse;
  isProcessing: boolean;
  onSave: (item: Qna, isEdit?: boolean) => void;
  onDelete: (item: Qna) => void;
}

const QnaEditor: React.FC<QnaEditorProps> = (props) => {
  const { account, isProcessing, onSave, onDelete } = props;

  /*
   * ************** Providers *********************
   */
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { dataService } = useAppServices();
  const { userGroups } = useAuth();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna.editor'
  });

  /*
   * ************** State Vars *********************
   */
  const [page, setPage] = useState(1);
  const [itemExpanded, setItemExpanded] = useState<number | false | undefined>(
    false
  );
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  /*
   * ************** Hooks *********************
   */
  const { data: numPages } = useQuery({
    queryKey: ['qnas', 'pages'],
    queryFn: async () => {
      // Get the tags the user has permissions for
      const tags = getAllowedTagsForUser(account, userGroups);
      const numItems = (await dataService.getQnaCount(tags)) ?? 0;
      return Math.ceil(numItems / appConfig.App.QnaPageSize);
    }
  });

  const {
    isLoading,
    isError,
    error,
    data: qnas
  } = useQuery({
    queryKey: ['qnas', 'pages', page],
    queryFn: async () => {
      // Get the tags the user has permissions for
      const tags = getAllowedTagsForUser(account, userGroups);
      return await dataService.getQnas({
        page,
        tags
      });
    }
  });

  /*
   * ************** Helper Function(s) *********************
   */
  const handleItemSelected =
    (qna: Qna) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setItemExpanded(isExpanded ? qna.id : false);
    };

  const handleSave = (
    id: number,
    question: string,
    answer: string,
    tags: string[]
  ) => {
    if (qnas) {
      const qna = qnas.find((i) => i.id === id);
      if (qna) {
        qna.question = question;
        qna.answer = answer;
        qna.tags = tags;
        onSave(qna, true);
      }
    }
  };

  const handleDelete = (item: Qna) => {
    onDelete(item);
  };

  const availableTags = Array.from(
    new Set(account.groupTags.flatMap((groupTag) => groupTag.tags))
  );

  /*
   * ************** Render *********************
   */
  return (
    <>
      {isLoading || isProcessing ? (
        <Box sx={{ display: 'flex' }}>
          <Stack sx={{ flexGrow: 1 }} spacing={1}>
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={theme.spacing(6)}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={theme.spacing(60)}
            />
          </Stack>
        </Box>
      ) : isError ? (
        <div>{`${t('error')}: ${(error as Error).message}`}</div>
      ) : (
        <>
          <Stack spacing={3}>
            <QnaEditorActionBar
              onAdd={() => setShowCreateDialog(true)}
              onRefresh={() =>
                queryClient.invalidateQueries({
                  queryKey: ['pages', page],
                  refetchType: 'none'
                })
              }
            />
            <Box>
              {qnas.map((qna) => (
                <QnaItem
                  key={qna.id}
                  item={qna}
                  tags={availableTags}
                  expanded={itemExpanded === qna.id}
                  onSelected={handleItemSelected}
                  onSave={handleSave}
                  onDelete={handleDelete}
                />
              ))}
            </Box>
            <Pagination
              count={numPages}
              page={page}
              onChange={(_event, value) => setPage(value)}
            />
          </Stack>
          <CreateQnaPopup
            tags={availableTags}
            open={showCreateDialog}
            onClose={() => setShowCreateDialog(false)}
            onSave={(q, a, tags) => {
              const qnaPair = new Qna(q, a, tags);
              setShowCreateDialog(false);
              onSave(qnaPair);
            }}
          />
        </>
      )}
    </>
  );
};

export default QnaEditor;
