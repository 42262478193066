import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AlertMessage } from 'contexts/AlertContext';
import { useAlerts } from 'hooks/useAlerts';

const AlertBar: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const { alert, clearAlerts } = useAlerts();
  const { t } = useTranslation('alerts');

  /*
   * ************** Helper Functions *********************
   */
  const handleClose = () => {
    clearAlerts();
  };

  /*
   * ************** Render *********************
   */
  const renderAlertInfo = (alert?: AlertMessage) => {
    if (alert) {
      switch (alert.severity) {
        case 'error':
          return (
            <Alert severity={alert.severity} onClose={handleClose}>
              {t('error')}: {alert.message}
            </Alert>
          );
        case 'warning':
          return (
            <Alert severity={alert.severity} onClose={handleClose}>
              {t('warn')}: {alert.message}
            </Alert>
          );
        case 'info':
          return (
            <Alert severity={alert.severity} onClose={handleClose}>
              {t('info')}: {alert.message}
            </Alert>
          );
      }
    }
  };

  return (
    <Snackbar
      open={!!alert}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {renderAlertInfo(alert)}
    </Snackbar>
  );
};

export default AlertBar;
