import { PaletteMode, Theme, ThemeOptions, createTheme } from '@mui/material';

import { LightModeColorPalette, DarkModeColorPalette } from './colors';

export function getTheme(mode: PaletteMode) {
  const baseOverrides = getBaseOverrides(mode);
  let theme = createTheme(baseOverrides);
  theme = addComponentOverrides(theme, mode);
  return theme;
}

function getBaseOverrides(mode: PaletteMode): ThemeOptions {
  return {
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            background: {
              default: LightModeColorPalette.background,
              paper: LightModeColorPalette.cardBackground
            },
            primary: {
              main: LightModeColorPalette.headerBarBackground
            },
            text: {
              primary: LightModeColorPalette.textPrimary,
              secondary: LightModeColorPalette.textSecondary
            },
            error: {
              main: LightModeColorPalette.errorMain
            },
            border: {
              default: LightModeColorPalette.borderPrimary
            }
          }
        : {
            background: {
              default: DarkModeColorPalette.background
            },
            primary: {
              main: DarkModeColorPalette.headerBarBackground
            },
            text: {
              primary: DarkModeColorPalette.textPrimary,
              secondary: DarkModeColorPalette.textSecondary
            },
            error: {
              main: DarkModeColorPalette.errorMain
            },
            border: {
              default: DarkModeColorPalette.borderPrimary
            }
          })
    },
    typography: {
      h2: {
        fontFamily: 'Noto sans',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: 0.9
      },
      h3: {
        fontFamily: 'Noto sans',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: 0.9
      },
      h5: {
        fontFamily: 'Righteous',
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: 0.82,
        letterSpacing: '0.05em'
      }
    },
    shape: {
      borderRadius: 8
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true
        }
      }
    },
    mixins: {
      toolbar: {
        minHeight: '48px'
      }
    }
  };
}

function addComponentOverrides(theme: Theme, mode: PaletteMode): Theme {
  return {
    ...theme,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%'
          },
          body: {
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%'
          },
          '#root': {
            width: '100%',
            height: '100%'
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor:
              mode === 'light'
                ? LightModeColorPalette.navMenuBackground
                : DarkModeColorPalette.navMenuBackground
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          },
          contained: {
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none'
            }
          },
          outlined: {
            color: 'inherit'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
              padding: 0,
              minWidth: 0
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1)
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              borderLeft: '4px solid',
              ...(mode === 'light'
                ? {
                    backgroundColor: LightModeColorPalette.menuButtonHover,
                    color: LightModeColorPalette.menuButtonHoverText
                  }
                : {
                    backgroundColor: DarkModeColorPalette.menuButtonHover,
                    color: DarkModeColorPalette.textPrimary
                  }),
              '&:hover, &:focus': {
                ...(mode === 'light'
                  ? {
                      backgroundColor: LightModeColorPalette.menuButtonHover,
                      color: LightModeColorPalette.menuButtonHoverText
                    }
                  : {
                      backgroundColor: DarkModeColorPalette.menuButtonHover,
                      color: DarkModeColorPalette.textPrimary
                    })
              }
            },
            '&:hover, &:focus': {
              ...(mode === 'light'
                ? {
                    backgroundColor: LightModeColorPalette.menuButtonHover,
                    color: LightModeColorPalette.menuButtonHoverText
                  }
                : {
                    backgroundColor: DarkModeColorPalette.menuButtonHover,
                    color: DarkModeColorPalette.textPrimary
                  })
            }
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: theme.typography.fontWeightMedium
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
            minWidth: 'auto',
            marginRight: theme.spacing(2),
            '& svg': {
              fontSize: 20
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: '32px',
            height: '32px'
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            ...(mode === 'light'
              ? {
                  color: LightModeColorPalette.textLink
                }
              : {
                  color: DarkModeColorPalette.textLink
                })
          }
        }
      }
    }
  };
}
