import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';
import { useTranslation } from 'react-i18next';

interface ChatAnswerErrorProps {
  error: string;
  onRetry: () => void;
}

const ChatAnswerError: React.FC<ChatAnswerErrorProps> = (
  props: ChatAnswerErrorProps
) => {
  const { error, onRetry } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.answer.error'
  });
  const isDarkMode = theme.palette.mode === 'dark';

  /*
   * ************** Render **************
   */
  return (
    <Stack
      spacing={1}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        padding: '10px',
        mt: 1,
        minWidth: '35%',
        background: isDarkMode
          ? DarkModeColorPalette.errorMain
          : LightModeColorPalette.errorMain
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ErrorIcon
          sx={{
            mr: '10px',
            color: isDarkMode
              ? DarkModeColorPalette.errorIcon
              : LightModeColorPalette.errorIcon
          }}
        />
        <Typography
          variant="body2"
          sx={{
            lineHeight: '1.7',
            color: isDarkMode
              ? DarkModeColorPalette.textError
              : LightModeColorPalette.textError
          }}
        >
          {error}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="error"
          sx={{
            color: isDarkMode
              ? 'rgba(204, 204, 204, 1)'
              : LightModeColorPalette.errorMain,
            backgroundColor: isDarkMode
              ? DarkModeColorPalette.errorIcon
              : LightModeColorPalette.errorIcon
          }}
          onClick={onRetry}
        >
          {t('retry')}
        </Button>
      </Box>
    </Stack>
  );
};

export default ChatAnswerError;
