import { List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Citation } from 'services/api/models';

type ParsedSupportingContentItem = {
  title: string;
  content: string;
};

interface SupportingContentProps {
  supportingContent: Citation[];
}

const SupportingContent: React.FC<SupportingContentProps> = (
  props: SupportingContentProps
) => {
  const { supportingContent } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.analysis.tabs.supportingContent'
  });

  /*
   * ************** Helper Functions **************
   */
  const parseSupportingContentItem = (
    item: Citation
  ): ParsedSupportingContentItem => {
    const docTitle = item.title.replace(/\.[^/.]+$/, '');
    const title = `${docTitle}, ${t('docTitleSection')} ${item.section}`;
    const content = item.content;

    return {
      title,
      content: content ?? ''
    };
  };

  /*
   * ************** Render **************
   */
  return (
    <List>
      {supportingContent.map((content, i) => {
        const parsedContent = parseSupportingContentItem(content);
        return (
          <ListItem
            key={i}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              padding: '10px',
              my: 1,
              background:
                theme.palette.mode === 'dark'
                  ? '#605E5C'
                  : 'rgba(39, 105, 203, 0.08)'
            }}
          >
            <Stack spacing={1}>
              <Typography variant="h2">{parsedContent.title}</Typography>
              <Typography variant="body2">{parsedContent.content}</Typography>
            </Stack>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SupportingContent;
