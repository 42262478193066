import React from 'react';
import {
  Navigate,
  NavigateFunction,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  InteractionStatus,
  NavigationClient,
  NavigationOptions,
  PublicClientApplication
} from '@azure/msal-browser';

// I18n
import './i18n/config';

// Pages
import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';
import Layout from 'pages/Layout/Layout';
import KnowledgeBasePage from 'pages/KnowledgeBasePage/KnowledgeBasePage';
import LoginPage from 'pages/LoginPage/LoginPage';
import ChatPage from 'pages/ChatPage/ChatPage';
import OptionsPage from 'pages/OptionsPage/OptionsPage';
import NotFoundPage from './pages/error/NotFoundPage';
import UnauthorizedPage from './pages/error/UnauthorizedPage';
import ServerErrorPage from './pages/error/ServerErrorPage';

// Components
import { AppServiceProvider } from 'contexts/AppServiceContext';
import { AuthContextProvider } from 'contexts/AuthContext';
import { getAllowedRolesForRoute } from 'config/authConfig';
import { useAuth } from 'hooks/useAuth';
import { haveCommonElements } from 'util/arrays';
import { CircularProgress } from '@mui/material';
import { AlertContextProvider } from 'contexts/AlertContext';

// QueryClient for react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 500
    }
  }
});

class CustomNavigationClient extends NavigationClient {
  private navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

export interface AppProps {
  pca: PublicClientApplication;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const { pca } = props;
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <AppServiceProvider>
            <AlertContextProvider>
              <Pages />
            </AlertContextProvider>
          </AppServiceProvider>
        </QueryClientProvider>
      </AuthContextProvider>
    </MsalProvider>
  );
};

// Wrapper component to protect routes
const ProtectedRoute: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const { userRoles } = useAuth();
  const location = useLocation();

  const isAuthorized = haveCommonElements(
    getAllowedRolesForRoute(location.pathname),
    userRoles
  );

  if (inProgress !== InteractionStatus.None) {
    return <CircularProgress color="inherit" />;
  } else if (!isAuthenticated) {
    // Reroute unauthenticated users to login page
    return <Navigate to="/login" replace />;
  } else if (!isAuthorized) {
    // Prevent unauthorized access to page
    return <Navigate to="/unauthorized" />;
  } else {
    return <Outlet />;
  }
};

const Pages: React.FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Private Routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path={ROUTES.ASK_EMELY_ROUTE}
            element={<ChatPage docChat key="1" />}
          />
          <Route
            path={ROUTES.MY_WORKSPACE_ROUTE}
            element={<ChatPage key="2" />}
          />
          <Route
            path={ROUTES.KNOWLEDGE_BASE_ROUTE}
            element={<KnowledgeBasePage />}
          />
          <Route
            path={ROUTES.ACCOUNT_SETTINGS_ROUTE}
            element={<OptionsPage />}
          />
        </Route>

        {/* Public Routes */}
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/error" element={<ServerErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default App;
