import { Box, Typography, useTheme } from '@mui/material';
import Container from '@mui/material/Container';

const UnauthorizedPage: React.FC = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box
        sx={{
          my: theme.spacing(6)
        }}
      >
        <Typography variant="h2">Unauthorized</Typography>
        <Typography variant="body1">
          You are not authorized to use this app. Please contact your IT
          administrator to request access
        </Typography>
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
