import {
  AccountUpdateRequestBody,
  AccountsResponse,
  FileData,
  GetQnasOptions,
  Qna
} from 'services/api/models';
import { appConfig } from 'config/appConfig';
import { DataApiClient } from 'services/api/dataApiClient';

export class DataService {
  private dataApiClient: DataApiClient;

  constructor(tenantId: string, apiKey: string) {
    this.dataApiClient = new DataApiClient(tenantId, apiKey);
  }

  async getAccount(): Promise<AccountsResponse> {
    try {
      return this.dataApiClient.getAccount();
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error fetching account information: ${error.message}`);
      throw error;
    }
  }

  async updateAccount(account: AccountUpdateRequestBody): Promise<void> {
    try {
      this.dataApiClient.updateAccount(account);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error fetching account information: ${error.message}`);
      throw error;
    }
  }

  async getQnaCount(tags: Array<string> = ['public']): Promise<number> {
    try {
      const response = await this.dataApiClient.getQnas({
        tags
      });
      return response.count;
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error fetching Q&As: ${error.message}`);
      throw error;
    }
  }

  async getQnas(options?: {
    page?: number;
    tags?: Array<string>;
  }): Promise<Qna[]> {
    const apiOptions: GetQnasOptions = {};
    if (options) {
      const { page, tags } = options;
      if (page) {
        apiOptions.top = appConfig.App.QnaPageSize;
        apiOptions.skip = (page - 1) * appConfig.App.QnaPageSize;
      }

      if (tags) {
        apiOptions.tags = tags;
      } else {
        apiOptions.tags = ['public'];
      }
    }

    try {
      const response = await this.dataApiClient.getQnas(apiOptions);
      return response.qnas;
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error fetching Q&As: ${error.message}`);
      throw error;
    }
  }

  async uploadQna(qna: Qna): Promise<void> {
    try {
      // Default to 'public' if no tags are set
      if (!qna.tags) {
        qna.tags = ['public'];
      }
      this.dataApiClient.createQna(qna);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error uploading Q&A item: ${error.message}`);
      throw error;
    }
  }

  async updateQna(qna: Qna): Promise<void> {
    try {
      // Default to 'public' if no tags are set
      if (!qna.tags) {
        qna.tags = ['public'];
      }
      this.dataApiClient.updateQna(qna);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error updating Q&A item: ${error.message}`);
      throw error;
    }
  }

  async deleteQna(qna: Qna): Promise<void> {
    try {
      this.dataApiClient.deleteQna(qna);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error deleting Q&A item: ${error.message}`);
      throw error;
    }
  }

  async getFileData(tags: Array<string> = ['public']): Promise<FileData[]> {
    try {
      const response = await this.dataApiClient.getAllFilesMetadata({
        tags
      });
      return response.files;
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error uploading file: ${error.message}`);
      throw error;
    }
  }

  async uploadFiles(
    files: File[],
    tags: string[] = ['public'],
    descriptions?: Record<string, string>
  ): Promise<void> {
    try {
      // Default to 'public' if no tags are set
      this.dataApiClient.indexFiles(
        files,
        tags.length > 0 ? tags : ['public'],
        descriptions
      );
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error uploading file: ${error.message}`);
      throw error;
    }
  }

  async updateFileTags(
    fileName: string,
    tags: Array<string> = ['public']
  ): Promise<void> {
    try {
      // Default to 'public' if no tags are set
      this.dataApiClient.updateFileMetadata(fileName, {
        tags: tags.length > 0 ? tags : ['public']
      });
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error updating file metadata: ${error.message}`);
      throw error;
    }
  }

  async fetchFile(fileName: string): Promise<Blob> {
    return this.dataApiClient.getFile(fileName);
  }

  async deleteFile(fileName: string): Promise<void> {
    try {
      this.dataApiClient.deleteFile(fileName);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error deleting file: ${error.message}`);
      throw error;
    }
  }

  async emailChatTranscript(
    recipient: string,
    transcriptData: Blob,
    transcriptName: string
  ): Promise<void> {
    try {
      const file = new File([transcriptData], transcriptName, {
        type: 'text/plain'
      });
      this.dataApiClient.sendChatCopy(recipient, file);
    } catch (_error: unknown) {
      const error = _error as Error;
      console.error(`Error sending chat transcript: ${error.message}`);
      throw error;
    }
  }
}
