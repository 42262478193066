import { Configuration, LogLevel } from '@azure/msal-browser';
import { isDevelopment } from './appConfig';
import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: isDevelopment()
      ? `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`
      : 'https://login.microsoftonline.com/organizations/v2.0',
    postLogoutRedirectUri: `${process.env.REACT_APP_URL}/login`
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE || isEdge
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const protectedResources = {
  chatApi: {
    baseUrl: `${process.env.REACT_APP_CHAT_API_URL}`
  },
  dataApi: {
    baseUrl: `${process.env.REACT_APP_DATA_API_URL}`
  },
  securityApi: {
    baseUrl: `${process.env.REACT_APP_SECURITY_API_URL}`,
    scope: `${process.env.REACT_APP_SECURITY_API_SCOPE}`
  }
};

export enum AppRoles {
  Administrator = <any>'ReadWrite.All',
  KnowledgeMaster = <any>'ReadWrite',
  User = <any>'Read'
}

export const getRoleName = (role: string): string | undefined =>
  Object.keys(AppRoles)[Object.values(AppRoles).indexOf(role)];

export function getAllowedRolesForRoute(route: string): string[] {
  switch (route) {
    case ROUTES.KNOWLEDGE_BASE_ROUTE:
      return [
        AppRoles[AppRoles.Administrator],
        AppRoles[AppRoles.KnowledgeMaster]
      ];
    default:
      return [
        AppRoles[AppRoles.Administrator],
        AppRoles[AppRoles.KnowledgeMaster],
        AppRoles[AppRoles.User]
      ];
  }
}
