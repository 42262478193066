import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDevelopment } from 'config/appConfig';

// Namespaces
import enDefault from './en/default.json';
import enAlerts from './en/alerts.json';
import enChat from './en/chat.json';
import enHeader from './en/header.json';
import enHome from './en/home.json';
import enKnowledgeBase from './en/knowledgeBase.json';
import enLogin from './en/login.json';
import enNavMenu from './en/navMenu.json';
import enSettings from './en/settings.json';

import esDefault from './es/default.json';
import esAlerts from './es/alerts.json';
import esChat from './es/chat.json';
import esHeader from './es/header.json';
import esHome from './es/home.json';
import esKnowledgeBase from './es/knowledgeBase.json';
import esLogin from './es/login.json';
import esNavMenu from './es/navMenu.json';
import esSettings from './es/settings.json';

// i18n instance
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Languages
    resources: {
      en: {
        default: enDefault,
        alerts: enAlerts,
        chat: enChat,
        header: enHeader,
        home: enHome,
        knowledgeBase: enKnowledgeBase,
        login: enLogin,
        navMenu: enNavMenu,
        settings: enSettings
      },
      es: {
        default: esDefault,
        alerts: esAlerts,
        chat: esChat,
        header: esHeader,
        home: esHome,
        knowledgeBase: esKnowledgeBase,
        login: esLogin,
        navMenu: esNavMenu,
        settings: esSettings
      }
    },
    defaultNS: 'default',
    fallbackNS: 'default',
    fallbackLng: 'en',

    // Detection options
    detection: {
      caches: [],
      order: [
        'navigator',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain'
      ]
    },

    // Other settings
    debug: isDevelopment(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
