import { protectedResources } from 'config/authConfig';
import { ApiClient } from './apiClient';
import {
  AcquireTokenFunction,
  OAuthAuthorizationProvider
} from './authorizationProvider';
import { GroupsResponse } from './models';

export class SecurityApiClient extends ApiClient {
  constructor(tenantId: string, acquireToken: AcquireTokenFunction) {
    super(
      protectedResources.securityApi.baseUrl,
      new OAuthAuthorizationProvider(tenantId, acquireToken)
    );
  }

  async getApiKey(tenantId: string): Promise<string> {
    const endpoint = `/keys/${tenantId}`;
    return this.get<string>(endpoint, {
      scope: protectedResources.securityApi.scope
    });
  }

  async generateApiKey(tenantId: string): Promise<string> {
    const endpoint = `/keys`;
    return this.post<string>(endpoint, {
      scope: protectedResources.securityApi.scope,
      requestBody: {
        tenantId
      }
    });
  }

  async getTenantSecurityGroups(tenantId: string): Promise<GroupsResponse> {
    const endpoint = `/groups/${tenantId}`;
    return this.get<GroupsResponse>(endpoint, {
      scope: protectedResources.securityApi.scope
    });
  }
}
