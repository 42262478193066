import {
  IconButton,
  Avatar,
  Popover,
  Box,
  Typography,
  useTheme,
  Divider,
  MenuItem
} from '@mui/material';
import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/useAuth';

interface AccountPopoverProps {}

const AccountPopover: React.FC<AccountPopoverProps> = () => {
  /*
   * ************** Providers **************
   */
  const { instance } = useMsal();
  const { userName, userEmail, userRoles } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation('header', {
    keyPrefix: 'accountMenu'
  });

  /*
   * ************** State Vars **************
   */
  const [open, setOpen] = useState<(EventTarget & Element) | undefined>(
    undefined
  );

  /*
   * ************** Helper Functions **************
   */
  const handleOpen = (event: React.MouseEvent) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleLogout = async () => {
    await instance.logoutRedirect();
  };

  /*
   * ************** Render **************
   */
  return (
    <>
      <IconButton onClick={handleOpen} color="inherit" sx={{ p: 0 }}>
        <Avatar alt="My Avatar">
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1,
              ml: 0.75,
              width: 200
            }
          }
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle1" noWrap>
            {userName}
          </Typography>
          <Typography variant="subtitle2">{userRoles[0]}</Typography>
          <Typography
            variant="caption"
            sx={{ color: theme.palette.text.secondary }}
            noWrap
          >
            {userEmail}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: 'body2', color: theme.palette.error.main, py: 1.5 }}
        >
          {t('logOutButton')}
        </MenuItem>
      </Popover>
    </>
  );
};

export default AccountPopover;
