import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  Button,
  useTheme,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import { Qna } from 'services/api/models';
import { useTranslation } from 'react-i18next';

interface EditQnaPopupProps {
  item: Qna;
  tags: string[];
  open: boolean;
  onClose: () => void;
  onSave: (
    id: number,
    question: string,
    answer: string,
    tags: string[]
  ) => void;
}

const EditQnaPopup: React.FC<EditQnaPopupProps> = (
  props: EditQnaPopupProps
) => {
  const { item, tags, open, onClose, onSave } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna.editor.popup.edit'
  });

  /*
   * ************** State Vars *********************
   */
  const [currentQuestion, setCurrentQuestion] = useState<string>(item.question);
  const [currentAnswer, setCurrentAnswer] = useState<string>(item.answer);
  const [currentTags, setCurrentTags] = useState<string[]>(item.tags ?? []);

  /*
   * ************** Hooks *********************
   */
  useEffect(() => {
    setCurrentQuestion(item.question);
    setCurrentAnswer(item.answer);
    setCurrentTags(item.tags ?? []);
  }, [props]);

  /*
   * ************** Helper Function(s) *********************
   */
  const handleTagsChanged = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    // On autofill we get a stringified value
    setCurrentTags(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSaveClicked = () => {
    if (item.id) {
      onSave(item.id, currentQuestion, currentAnswer, currentTags);
    }
  };

  /*
   * ************** Render *********************
   */
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: theme.spacing(2)
          }}
        >
          <TextField
            sx={{
              my: theme.spacing(1)
            }}
            fullWidth
            label={t('questionLabel')}
            value={currentQuestion}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentQuestion(event.target.value)
            }
          />
          <TextField
            sx={{
              my: theme.spacing(1)
            }}
            fullWidth
            multiline
            label={t('answerLabel')}
            value={currentAnswer}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentAnswer(event.target.value)
            }
          />
          <Box>
            <FormControl>
              <InputLabel>{t('tagsLabel')}</InputLabel>
              <Select
                multiple
                value={currentTags}
                onChange={handleTagsChanged}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{
                          backgroundColor:
                            theme.palette.mode === 'light'
                              ? 'rgba(157, 205, 255, 0.49)'
                              : 'rgba(26, 138,255, 0.49)',
                          mx: '2px'
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          startIcon={<CloseIcon />}
          onClick={onClose}
        >
          {t('cancelButton')}
        </Button>
        <Button
          variant="contained"
          color="info"
          startIcon={<SaveIcon />}
          onClick={handleSaveClicked}
        >
          {t('saveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQnaPopup;
