import { Typography } from '@mui/material';
import Container from '@mui/material/Container';

const ServerErrorPage: React.FC = () => {
  return (
    <Container>
      <Typography variant="h2">Server Error</Typography>
      <Typography variant="body1">
        Our server has encountered an unexpected error, please refresh or try
        again later
      </Typography>
    </Container>
  );
};

export default ServerErrorPage;
