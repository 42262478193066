import { Box, useTheme } from '@mui/material';
//import LaunchIcon from '@mui/icons-material/Launch';
import Select from 'react-select';
import { HiOutlinePlus } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
//import { useAuth } from '../providers/AuthProvider';
//import { clientSharePointSites } from '../../config/config';

interface QnaEditorActionBarProps {
  onAdd: () => void;
  onRefresh: () => void;
}

const QnaEditorActionBar: React.FC<QnaEditorActionBarProps> = (
  props: QnaEditorActionBarProps
) => {
  /*
   * ************** Providers *********************
   */
  //const { userInfo } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna.editor.actionBar'
  });

  /*
   * ************** Render *********************
   */
  return (
    <Box
      sx={{
        mt: theme.spacing(1)
      }}
    >
      <Box
        sx={{
          padding: '5px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              lg: 'row',
              md: 'row',
              sm: 'row',
              xs: 'column'
            }
          }}
        >
          <Box
            sx={{
              width: {
                lg: '50%',
                md: '50%',
                sm: '50%',
                xs: '100%'
              },
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}
          >
            <span>{t('selectQnaSet')}</span>
            <Select isDisabled={true} placeholder={t('comingSoon')} />
          </Box>
          <Box
            component="button"
            onClick={props.onAdd}
            sx={{
              backgroundColor: 'info.main',
              padding: '0px 30px',
              borderRadius: '10px',
              marginTop: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.background.default,
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
              height: '45px'
            }}
          >
            {t('addQna')}
            <div style={{ fontSize: '20px', marginTop: '5px' }}>
              <HiOutlinePlus />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QnaEditorActionBar;
