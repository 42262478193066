export const LightModeColorPalette = {
  // App color palette
  background: '#FFFEFF',

  // Header bar
  headerBarBackground: '#2769CB',

  // Card
  cardBackground: 'rgba(247, 247, 249, 1)',

  // Text color palette
  textPrimary: 'rgba(0,0,0,0.87)',
  textSecondary: 'rgba(0,0,0,0.6)',
  textAlternate: '#FFFFFF',
  textError: '#FFFFFF',
  textLink: '#2769CB',

  // Menu color palette
  navMenuBackground: '#E9E9E9',

  // Effect color palette
  menuButtonHover: 'rgba(39, 105, 203, 0.08)',
  menuButtonHoverText: '#2769CB',

  // Effect border color palette
  borderPrimary: '#e0e0e0',

  // Error color palette
  errorMain: 'rgba(194, 0, 35, 1)',
  errorIcon: '#FFFFFF'
};

export const DarkModeColorPalette = {
  // App color palette
  background: '#1B1A19',

  // Header bar
  headerBarBackground: '#252423',

  // Card
  cardBackground: '#252423',

  // Text color palette
  textPrimary: '#FFFFFF',
  textSecondary: 'rgba(255,255,255,0.87)',
  textAlternate: '#FFFFFF',
  textError: '#000000',
  textLink: '#0f84e5',

  // Menu color palette
  navMenuBackground: '#323130',

  // Effect color palette
  menuButtonHover: '#605E5C',

  // Effect border color palette
  borderPrimary: '#3a3737',

  // Error color palette
  errorMain: 'rgba(207, 102, 121, 1)',
  errorIcon: 'rgba(176, 0, 32, 1)'
};
