import { Box, Typography, useTheme } from '@mui/material';

import { ChatMessage } from 'services/api/models';

import ProfileGroup from '../../../assets/ProfileLogo.png';

interface UserChatMessageProps {
  message: ChatMessage;
}

const UserChatMessage: React.FC<UserChatMessageProps> = (
  props: UserChatMessageProps
) => {
  const { message } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();

  /*
   * ************** Render **************
   */
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding: '10px',
          mr: 1,
          background:
            theme.palette.mode === 'dark'
              ? '#605E5C'
              : 'rgba(39, 105, 203, 0.08)'
        }}
      >
        <Typography variant="body2">{message.content}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
        <img src={ProfileGroup} alt="" />
      </Box>
    </>
  );
};

export default UserChatMessage;
