/**
 * Helper function that downloads a file to the local system from
 * the browser
 * @param fileName the name of the file to download
 * @param file the file to download
 */
export const downloadFile = (fileName: string, file: Blob) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};
