import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { AppRoles } from 'config/authConfig';

export const ROUTES = {
  ASK_EMELY_ROUTE: '/ask-emely',
  MY_WORKSPACE_ROUTE: '/my-workspace',
  KNOWLEDGE_BASE_ROUTE: '/knowledge-base',
  ACCOUNT_SETTINGS_ROUTE: '/account-settings'
};

export type MenuItem = {
  title: string;
  icon: JSX.Element;
  route: string;
  allowedRoles: string[];
};

export const navMenu: MenuItem[] = [
  {
    title: 'askEmely',
    icon: <AutoAwesomeIcon />,
    route: ROUTES.ASK_EMELY_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'myWorkspace',
    icon: <AutoAwesomeIcon />,
    route: ROUTES.MY_WORKSPACE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'knowledgeBase',
    icon: <PsychologyAltIcon />,
    route: ROUTES.KNOWLEDGE_BASE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  }
];

export default navMenu;
