import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  InputLabel,
  TextField,
  Button,
  Skeleton
} from '@mui/material';

import {
  AccountUpdateRequestBody,
  AccountsResponse
} from 'services/api/models';
import { useAuth } from 'hooks/useAuth';
import { haveCommonElements } from 'util/arrays';
import { AppRoles } from 'config/authConfig';
import { useTranslation } from 'react-i18next';

export interface AccountInfoTabProps {
  account?: AccountsResponse;
  loading: boolean;
  onUpdate: (accountUpdate: AccountUpdateRequestBody) => void;
  onError: (error: Error) => void;
}

const AccountInfoTab: React.FC<AccountInfoTabProps> = (props) => {
  const { account, loading, onUpdate } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { userRoles } = useAuth();
  const { t } = useTranslation('settings', {
    keyPrefix: 'accountInfo'
  });

  /*
   * ************** State Vars *********************
   */
  const [currentInfo, setCurrentInfo] = useState(account);

  /*
   * ************** Helper Functions *********************
   */
  const handleSaveClicked = (accountInfo?: AccountsResponse) => {
    if (accountInfo) {
      onUpdate(
        new AccountUpdateRequestBody(
          undefined,
          accountInfo.knowledgeMasterEmail
        )
      );
    }
  };

  /*
   * ************** Render *********************
   */
  // Only Administrators can edit knowledge master email
  const canEditKnowledgeMasterEmail = haveCommonElements(userRoles, [
    AppRoles[AppRoles.Administrator]
  ]);

  if (!account || loading) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        width="100%"
        height={theme.spacing(60)}
      />
    );
  }

  return (
    <>
      <Box>
        <Typography variant="h4">{account.name}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mb: theme.spacing(2), fontWeight: '700' }}
            >
              {t('title')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: theme.spacing(1) }}>
              <InputLabel
                sx={{
                  mb: 1,
                  color: theme.palette.text.primary
                }}
              >
                <Typography variant="subtitle1">{t('tenantId')}</Typography>
              </InputLabel>
              <TextField
                fullWidth
                value={account.tenantId}
                sx={{
                  background: theme.palette.background.paper
                }}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <InputLabel
                sx={{
                  mb: 1,
                  color: theme.palette.text.primary
                }}
              >
                <Typography variant="subtitle1">
                  {t('knowledgeMasterEmail')}
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                value={
                  currentInfo?.knowledgeMasterEmail ??
                  account.knowledgeMasterEmail
                }
                onChange={(e) => {
                  const updatedInfo = {
                    ...account,
                    knowledgeMasterEmail: e.target.value
                  };
                  setCurrentInfo(updatedInfo);
                }}
                sx={
                  !canEditKnowledgeMasterEmail
                    ? {
                        background: theme.palette.background.paper
                      }
                    : undefined
                }
                InputProps={{
                  readOnly: !canEditKnowledgeMasterEmail
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {canEditKnowledgeMasterEmail && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleSaveClicked(currentInfo)}
          >
            {t('saveButton')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default AccountInfoTab;
