import axios from 'axios';

import { AuthorizationProvider } from './authorizationProvider';

export abstract class ApiClient {
  constructor(
    protected readonly baseUrl: string,
    protected authorizationProvider: AuthorizationProvider
  ) {}

  async get<T>(
    endpoint: string,
    options?: { scope?: string; queryParameters?: any; headers?: any }
  ): Promise<T> {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader
    };
    const response = await axios.get<T>(`${this.baseUrl}/${strippedEndpoint}`, {
      params: options?.queryParameters,
      headers
    });

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.data;
  }

  async getBlob(
    endpoint: string,
    options?: { scope?: string; queryParameters?: any; headers?: any }
  ): Promise<Blob> {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader
    };
    const response = await axios.get<Blob>(
      `${this.baseUrl}/${strippedEndpoint}`,
      {
        params: options?.queryParameters,
        responseType: 'blob',
        headers
      }
    );

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.data;
  }

  async post<T>(
    endpoint: string,
    options: { scope?: string; requestBody?: any; headers?: any }
  ): Promise<T> {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader
    };
    const response = await axios.post<T>(
      `${this.baseUrl}/${strippedEndpoint}`,
      options?.requestBody,
      {
        headers
      }
    );

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.data;
  }

  async postStream(
    endpoint: string,
    options: { scope?: string; requestBody?: any; headers?: any }
  ) {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader,
      'Content-Type': 'application/json'
    };
    const response = await fetch(`${this.baseUrl}/${strippedEndpoint}`, {
      method: 'POST',
      body: JSON.stringify(options?.requestBody),
      headers
    });

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.body;
  }

  async put<T>(
    endpoint: string,
    options: { scope?: string; requestBody?: any; headers?: any }
  ): Promise<T> {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader
    };
    const response = await axios.put<T>(
      `${this.baseUrl}/${strippedEndpoint}`,
      options?.requestBody,
      {
        headers
      }
    );

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.data;
  }

  async patch<T>(
    endpoint: string,
    options?: { scope?: string; requestBody?: any; headers?: any }
  ): Promise<T> {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader
    };
    const response = await axios.patch<T>(
      `${this.baseUrl}/${strippedEndpoint}`,
      options?.requestBody,
      {
        headers
      }
    );

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.data;
  }

  async delete<T>(
    endpoint: string,
    options?: { scope?: string; headers?: any }
  ): Promise<T> {
    const strippedEndpoint = this._stripLeadingSlash(endpoint);
    const authorizationHeader = await this._getAuthorizationHeader(
      options?.scope
    );
    const headers = {
      ...options?.headers,
      ...authorizationHeader
    };
    const response = await axios.delete<T>(
      `${this.baseUrl}/${strippedEndpoint}`,
      {
        headers
      }
    );

    if (response.status >= 400) {
      throw new Error('Unexpected error during API request');
    }

    return response.data;
  }

  private async _getAuthorizationHeader(
    scope?: string
  ): Promise<Record<string, string>> {
    return this.authorizationProvider.getAuthorizationHeader(scope);
  }

  private _stripLeadingSlash(endpoint: string): string {
    if (endpoint.startsWith('/')) return endpoint.slice(1);
    return endpoint;
  }
}
