import { PropsWithChildren, createContext, useState } from 'react';

export type AlertMessage = {
  severity: 'info' | 'warning' | 'error';
  message: string;
};

interface AlertContextState {
  alert?: AlertMessage;
  sendAlert: (alert: AlertMessage) => void;
  clearAlerts: () => void;
}

const initialState: AlertContextState = {
  sendAlert: () => {},
  clearAlerts: () => {}
};

export const AlertContext = createContext<AlertContextState>(initialState);

export const AlertContextProvider: React.FC<PropsWithChildren> = (
  props: PropsWithChildren
) => {
  const { children } = props;
  const [alert, setAlert] = useState<AlertMessage | undefined>();

  const sendAlert = (alert: AlertMessage) => {
    setAlert(alert);
  };

  const clearAlerts = () => {
    setAlert(undefined);
  };

  return (
    <AlertContext.Provider value={{ alert, sendAlert, clearAlerts }}>
      {children}
    </AlertContext.Provider>
  );
};
