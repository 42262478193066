import { Box, Typography } from '@mui/material';

export type CustomTabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel: React.FC<CustomTabPanelProps> = (
  props: CustomTabPanelProps
) => {
  const { children, value, index } = props;

  return (
    <div role="tabpanel">
      {value === index && (
        <Box
          sx={{
            p: {
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1
            }
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default CustomTabPanel;
