import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme
} from '@mui/material';

import NAV_MENU from '../NavigatorMenu/NavigatorMenu';
import { useAuth } from 'hooks/useAuth';
import { haveCommonElements } from 'util/arrays';
import { useTranslation } from 'react-i18next';

const Navigator: React.FC<DrawerProps> = (props: DrawerProps) => {
  const { ...other } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { userRoles } = useAuth();
  const { t } = useTranslation('navMenu');

  /*
   * ************** State Vars *********************
   */
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('Home');

  /*
   * ************** Helper Function(s) *********************
   */
  const handleMenuItemSelected = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
  };

  /*
   * ************** Render **************
   */
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding sx={{ pt: theme.spacing(3) }}>
        {NAV_MENU.filter(({ allowedRoles }) =>
          haveCommonElements(allowedRoles, userRoles)
        ).map(({ title, icon, route }) => (
          <Box key={title}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to={route}
                selected={selectedMenuItem === title}
                sx={{
                  py: theme.spacing(1),
                  px: 3,
                  color: theme.palette.text.secondary
                }}
                onClick={() => handleMenuItemSelected(title)}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{t(title)}</ListItemText>
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default Navigator;
