import { AccountsResponse } from 'services/api/models';

export function getAllowedTagsForUser(
  account: AccountsResponse,
  userGroups: Array<string>
): Array<string> {
  // Cross-reference user groups with account tags and filter
  // out duplicates
  const allowedTags = Array.from(
    new Set(
      account.groupTags
        .filter((groupTag) =>
          userGroups.some((groupId) => groupId === groupTag.groupId)
        )
        .flatMap((groupTag) => groupTag.tags)
    )
  );

  // If no tags are found, default to 'public' tag
  if (allowedTags.length === 0) {
    allowedTags.push('public');
  }

  return allowedTags;
}
