export const appConfig = {
  App: {
    Environment: process.env.REACT_APP_ENVIRONMENT,
    QnaPageSize: 10
  }
};

export function isDevelopment() {
  return appConfig.App.Environment !== 'production';
}
