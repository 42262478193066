import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { Trans, useTranslation } from 'react-i18next';

interface DeleteDocumentPopupProps {
  open: boolean;
  fileName: string;
  onDelete: (fileName: string) => void;
  onClose: () => void;
}

const DeleteDocumentPopup: React.FC<DeleteDocumentPopupProps> = (
  props: DeleteDocumentPopupProps
) => {
  const { open, fileName, onDelete, onClose } = props;

  /*
   * ************** Providers **************
   */
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'documentStore.viewer.popup.delete'
  });

  /*
   * ************** Render *********************
   */
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Typography>
          <Trans>
            {t('message')} &apos;{{ fileName }}&apos;?
          </Trans>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => onDelete(fileName)}
        >
          {t('confirmButton')}
        </Button>
        <Button variant="contained" startIcon={<CloseIcon />} onClick={onClose}>
          {t('cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDocumentPopup;
